import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthOkResponse } from '@slovgen-ui/api'
import { DialogService } from 'primeng/dynamicdialog'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, mapTo } from 'rxjs/operators'
import { TokenResponse } from '../../models/token-response.model'
import { User } from '../../models/user.model'
import { LoginDialogComponent } from '../components/login-dialog/login-dialog.component'

const apiUrl = '/api/auth/'

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public static readonly JWT_TOKEN = 'JWT_TOKEN'
    public static readonly REFRESH_TOKEN = 'REFRESH_TOKEN'
    private currentUser: User | null = null
    decodedToken: any

    currentToken: string | null = null
    currentUser$ = new BehaviorSubject<User | null>(null)
    constructor(private http: HttpClient, private dialogService: DialogService) {
        this.loadFromLocalStorage()
        if (this.currentToken) {
            if (this.isTokenExpired()) {
                this.currentToken = null
                this.removeTokens()
                this.currentUser$.next(null)
                return
            }
            console.log('Recreate user from token')
        } else {
            console.log('User not logged in or expired')
        }
    }

    loadFromLocalStorage() {
        this.currentToken = localStorage.getItem(AuthService.JWT_TOKEN)
        if (this.currentToken) {
            this.createUserFromToken()
        }
    }

    getJwtToken() {
        return this.currentToken
    }
    hasRoleAssigned(roles: string[]): boolean {
        // console.log(`match roles ${roles} against ${this.currentUser.roles}`)
        if (!roles) {
            return true
        }

        if (!this.currentUser) {
            return false
        }

        return (
            this.currentUser.roles.filter((value) => roles.map((i) => i.toUpperCase()).includes(value.toUpperCase()))
                .length > 0
        )
    }

    login(user: { username: string; password: string }): Observable<boolean> {
        return this.http.post<any>(`${apiUrl}/login`, user).pipe(
            map((tokens) => this.doLoginUser(tokens)),
            mapTo(true)
        )
    }

    loginAfterInvite(tokenResponse: TokenResponse) {
        return this.doLoginUser(tokenResponse)
    }

    logout() {
        const isAdmin = this.currentUser?.isAdmin()
        this.doLogoutUser()
        window.location.href = `${location.origin}${isAdmin ? '/admin' : ''}`
    }

    isLoggedIn() {
        return !!this.currentToken
    }

    refreshToken(): Observable<boolean> {
        const reloginDialog = this.dialogService.open(LoginDialogComponent, {
            baseZIndex: 10000,
            closable: false,
            draggable: false,
            resizable: false,
            showHeader: false,
            modal: true,
            styleClass: 'login-modal',
        })
        return reloginDialog.onClose
        // return this.logout()
        // return this.http
        //     .post<any>(
        //         `${apiUrl}/refresh`,
        //         {
        //             refreshToken: this.refreshToken,
        //         },
        //         { context: new HttpContext().set(NO_DEFAULT_ERR_HANDLER_CONTEXT, true) }
        //     )
        //     .pipe(
        //         tap((tokens: TokenResponse) => {
        //             this.storeJwtToken(tokens.token)
        //         })
        //     )
    }

    private doLoginUser(tokens: TokenResponse) {
        this.currentToken = tokens.token
        this.createUserFromToken()
        this.storeTokens(tokens.token)
    }

    public refresh(tokens: AuthOkResponse) {
        this.currentToken = tokens.token
        this.createUserFromToken()
        this.storeTokens(tokens.token)
    }

    private createUserFromToken() {
        this.decodedToken = this.parseJwt(this.currentToken!)
        this.currentUser = new User()
        this.currentUser.locale = this.decodedToken.locale
        this.currentUser.name = this.decodedToken.given_name
        this.currentUser.username = this.decodedToken.email
        this.currentUser.roles = this.decodedToken.groups
        this.currentUser.customerId = this.decodedToken.customer_id
        this.currentUser$.next(this.currentUser)
    }

    private parseJwt(token: string) {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join('')
        )

        return JSON.parse(jsonPayload)
    }

    private doLogoutUser() {
        this.currentToken = null
        this.currentUser = null
        this.removeTokens()
    }

    private storeJwtToken(jwt: string) {
        localStorage.setItem(AuthService.JWT_TOKEN, jwt)
        this.decodedToken = this.parseJwt(jwt)
    }

    private storeTokens(token: string) {
        this.storeJwtToken(token)
    }

    private removeTokens() {
        localStorage.removeItem(AuthService.JWT_TOKEN)
        localStorage.removeItem(AuthService.REFRESH_TOKEN)
    }

    private isTokenExpired(): boolean {
        return this.decodedToken.exp * 1000 < new Date().getTime()
    }

    public getCurrentUser() {
        return this.currentUser
    }
}
