import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { User } from '@slovgen-ui/shared'
import { AuthService } from '../../services/auth.service'

@Component({
    selector: 'ui-inline-profile',
    templateUrl: './inline-profile.component.html',
    styles: [
        `
            .inline-profile {
                text-align: center;
                padding: 1em 0;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineProfileComponent implements OnInit {
    user?: User
    constructor(private authService: AuthService, private router: Router) {}

    ngOnInit() {
        this.user = this.authService.getCurrentUser() || undefined
    }

    logout() {
        this.authService.logout()
    }
}
