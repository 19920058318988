import { InvoiceMode, OrderStatus } from '@slovgen-ui/api'
import { SelectItem } from './select-item.model'

export const OrderStatusSelectItemsWEmpty: SelectItem<string>[] = [{ id: '', label: 'order.status.ALL' }].concat(
    Object.keys(OrderStatus).map((value) => ({
        id: OrderStatus[value as keyof typeof OrderStatus],
        label: `order.status.${OrderStatus[value as keyof typeof OrderStatus]}`,
    }))
)
export const OrderStatusSelectItems: SelectItem<string>[] = Object.keys(OrderStatus).map((value) => ({
    id: OrderStatus[value as keyof typeof OrderStatus],
    label: `order.status.${OrderStatus[value as keyof typeof OrderStatus]}`,
}))

export const InvoiceModeSelectItems: SelectItem<string>[] = Object.keys(InvoiceMode).map((value) => ({
    id: InvoiceMode[value as keyof typeof InvoiceMode],
    label: InvoiceMode[value as keyof typeof InvoiceMode],
}))
export const skCountries: { [key: string]: string } = {
    AF: 'Afganistan',
    AX: 'Alandy',
    AL: 'Alb\u00e1nsko',
    DZ: 'Al\u017e\u00edrsko',
    AS: 'Americk\u00e1 Samoa',
    VI: 'Americk\u00e9 Panensk\u00e9 ostrovy',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarkt\u00edda',
    AG: 'Antigua a Barbuda',
    AR: 'Argent\u00edna',
    AM: 'Arm\u00e9nsko',
    AW: 'Aruba',
    AU: 'Austr\u00e1lia',
    AZ: 'Azerbajd\u017ean',
    BS: 'Bahamy',
    BH: 'Bahrajn',
    BD: 'Banglad\u00e9\u0161',
    BB: 'Barbados',
    BE: 'Belgicko',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermudy',
    BT: 'Bhut\u00e1n',
    BY: 'Bielorusko',
    BO: 'Bol\u00edvia',
    BA: 'Bosna a Hercegovina',
    BW: 'Botswana',
    BV: 'Bouvetov ostrov',
    BR: 'Braz\u00edlia',
    IO: 'Britsk\u00e9 indickooce\u00e1nske \u00fazemie',
    VG: 'Britsk\u00e9 Panensk\u00e9 ostrovy',
    BN: 'Brunej',
    BG: 'Bulharsko',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CK: 'Cookove ostrovy',
    CW: 'Cura\u00e7ao',
    CY: 'Cyprus',
    TD: '\u010cad',
    CZ: '\u010cesko',
    ME: '\u010cierna Hora',
    CL: '\u010cile',
    CN: '\u010c\u00edna',
    DK: 'D\u00e1nsko',
    DM: 'Dominika',
    DO: 'Dominik\u00e1nska republika',
    DJ: 'D\u017eibutsko',
    EG: 'Egypt',
    EC: 'Ekv\u00e1dor',
    ER: 'Eritrea',
    EE: 'Est\u00f3nsko',
    SZ: 'Eswatini',
    ET: 'Eti\u00f3pia',
    FO: 'Faersk\u00e9 ostrovy',
    FK: 'Falklandy',
    FJ: 'Fid\u017ei',
    PH: 'Filip\u00edny',
    FI: 'F\u00ednsko',
    GF: 'Franc\u00fazska Guyana',
    PF: 'Franc\u00fazska Polyn\u00e9zia',
    TF: 'Franc\u00fazske ju\u017en\u00e9 a antarktick\u00e9 \u00fazemia',
    FR: 'Franc\u00fazsko',
    GA: 'Gabon',
    GM: 'Gambia',
    GH: 'Ghana',
    GI: 'Gibralt\u00e1r',
    GR: 'Gr\u00e9cko',
    GD: 'Grenada',
    GL: 'Gr\u00f3nsko',
    GE: 'Gruz\u00ednsko',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heardov ostrov a Macdonaldove ostrovy',
    NL: 'Holandsko',
    HN: 'Honduras',
    HK: 'Hongkong \u2013 OAO \u010c\u00edny',
    HR: 'Chorv\u00e1tsko',
    IN: 'India',
    ID: 'Indon\u00e9zia',
    IQ: 'Irak',
    IR: 'Ir\u00e1n',
    IE: '\u00cdrsko',
    IS: 'Island',
    IL: 'Izrael',
    JM: 'Jamajka',
    JP: 'Japonsko',
    YE: 'Jemen',
    JE: 'Jersey',
    JO: 'Jord\u00e1nsko',
    ZA: 'Ju\u017en\u00e1 Afrika',
    GS: 'Ju\u017en\u00e1 Georgia a Ju\u017en\u00e9 Sandwichove ostrovy',
    KR: 'Ju\u017en\u00e1 K\u00f3rea',
    SS: 'Ju\u017en\u00fd Sud\u00e1n',
    KY: 'Kajmanie ostrovy',
    KH: 'Kambod\u017ea',
    CM: 'Kamerun',
    CA: 'Kanada',
    CV: 'Kapverdy',
    BQ: 'Karibsk\u00e9 Holandsko',
    QA: 'Katar',
    KZ: 'Kazachstan',
    KE: 'Ke\u0148a',
    KG: 'Kirgizsko',
    KI: 'Kiribati',
    CC: 'Kokosov\u00e9 ostrovy',
    CO: 'Kolumbia',
    KM: 'Komory',
    CD: 'Kon\u017esk\u00e1 demokratick\u00e1 republika',
    CG: 'Kon\u017esk\u00e1 republika',
    CR: 'Kostarika',
    CU: 'Kuba',
    KW: 'Kuvajt',
    LA: 'Laos',
    LS: 'Lesotho',
    LB: 'Libanon',
    LR: 'Lib\u00e9ria',
    LY: 'L\u00edbya',
    LI: 'Lichten\u0161tajnsko',
    LT: 'Litva',
    LV: 'Loty\u0161sko',
    LU: 'Luxembursko',
    MO: 'Macao \u2013 OAO \u010c\u00edny',
    MG: 'Madagaskar',
    HU: 'Ma\u010farsko',
    MY: 'Malajzia',
    MW: 'Malawi',
    MV: 'Maldivy',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Maroko',
    MH: 'Marshallove ostrovy',
    MQ: 'Martinik',
    MU: 'Maur\u00edcius',
    MR: 'Maurit\u00e1nia',
    YT: 'Mayotte',
    UM: 'Men\u0161ie od\u013eahl\u00e9 ostrovy USA',
    MX: 'Mexiko',
    FM: 'Mikron\u00e9zia',
    MM: 'Mjanmarsko',
    MD: 'Moldavsko',
    MC: 'Monako',
    MN: 'Mongolsko',
    MS: 'Montserrat',
    MZ: 'Mozambik',
    NA: 'Nam\u00edbia',
    NR: 'Nauru',
    DE: 'Nemecko',
    NP: 'Nep\u00e1l',
    NE: 'Niger',
    NG: 'Nig\u00e9ria',
    NI: 'Nikaragua',
    NU: 'Niue',
    NF: 'Norfolk',
    NO: 'N\u00f3rsko',
    NC: 'Nov\u00e1 Kaled\u00f3nia',
    NZ: 'Nov\u00fd Z\u00e9land',
    OM: 'Om\u00e1n',
    IM: 'Ostrov Man',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palest\u00ednske \u00fazemia',
    PA: 'Panama',
    PG: 'Papua-Nov\u00e1 Guinea',
    PY: 'Paraguaj',
    PE: 'Peru',
    PN: 'Pitcairnove ostrovy',
    CI: 'Pobre\u017eie Slonoviny',
    PL: 'Po\u013esko',
    PR: 'Portoriko',
    PT: 'Portugalsko',
    AT: 'Rak\u00fasko',
    RE: 'R\u00e9union',
    GQ: 'Rovn\u00edkov\u00e1 Guinea',
    RO: 'Rumunsko',
    RU: 'Rusko',
    RW: 'Rwanda',
    PM: 'Saint Pierre a Miquelon',
    SV: 'Salv\u00e1dor',
    WS: 'Samoa',
    SM: 'San Mar\u00edno',
    SA: 'Saudsk\u00e1 Ar\u00e1bia',
    SN: 'Senegal',
    KP: 'Severn\u00e1 K\u00f3rea',
    MK: 'Severn\u00e9 Maced\u00f3nsko',
    MP: 'Severn\u00e9 Mari\u00e1ny',
    SC: 'Seychely',
    SL: 'Sierra Leone',
    SG: 'Singapur',
    SK: 'Slovensko',
    SI: 'Slovinsko',
    SO: 'Som\u00e1lsko',
    AE: 'Spojen\u00e9 arabsk\u00e9 emir\u00e1ty',
    GB: 'Spojen\u00e9 kr\u00e1\u013eovstvo',
    US: 'Spojen\u00e9 \u0161t\u00e1ty',
    RS: 'Srbsko',
    LK: 'Sr\u00ed Lanka',
    CF: 'Stredoafrick\u00e1 republika',
    SD: 'Sud\u00e1n',
    SR: 'Surinam',
    SJ: 'Svalbard a Jan Mayen',
    SH: 'Sv\u00e4t\u00e1 Helena',
    LC: 'Sv\u00e4t\u00e1 Lucia',
    BL: 'Sv\u00e4t\u00fd Bartolomej',
    KN: 'Sv\u00e4t\u00fd Kri\u0161tof a Nevis',
    MF: 'Sv\u00e4t\u00fd Martin (fr.)',
    SX: 'Sv\u00e4t\u00fd Martin (hol.)',
    ST: 'Sv\u00e4t\u00fd Tom\u00e1\u0161 a Princov ostrov',
    VC: 'Sv\u00e4t\u00fd Vincent a Grenad\u00edny',
    SY: 'S\u00fdria',
    SB: '\u0160alam\u00fanove ostrovy',
    ES: '\u0160panielsko',
    CH: '\u0160vaj\u010diarsko',
    SE: '\u0160v\u00e9dsko',
    TJ: 'Tad\u017eikistan',
    TW: 'Taiwan',
    IT: 'Taliansko',
    TZ: 'Tanz\u00e1nia',
    TH: 'Thajsko',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad a Tobago',
    TN: 'Tunisko',
    TR: 'Turecko',
    TM: 'Turkm\u00e9nsko',
    TC: 'Turks a Caicos',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukrajina',
    UY: 'Uruguaj',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatik\u00e1n',
    VE: 'Venezuela',
    CX: 'Viano\u010dn\u00fd ostrov',
    VN: 'Vietnam',
    TL: 'V\u00fdchodn\u00fd Timor',
    WF: 'Wallis a Futuna',
    ZM: 'Zambia',
    EH: 'Z\u00e1padn\u00e1 Sahara',
    ZW: 'Zimbabwe',
}

export const CountrySelectItemsSK: SelectItem<string>[] = Object.keys(skCountries).map((item) => {
    return { label: skCountries[item], id: item }
})

export const TzToCountry: { [key: string]: string } = {
    'Africa/Abidjan': 'CI',
    'Africa/Accra': 'GH',
    'Africa/Addis_Ababa': 'ET',
    'Africa/Algiers': 'DZ',
    'Africa/Asmara': 'ER',
    'Africa/Asmera': 'ER',
    'Africa/Bamako': 'ML',
    'Africa/Bangui': 'CF',
    'Africa/Banjul': 'GM',
    'Africa/Bissau': 'GW',
    'Africa/Blantyre': 'MW',
    'Africa/Brazzaville': 'CG',
    'Africa/Bujumbura': 'BI',
    'Africa/Cairo': 'EG',
    'Africa/Casablanca': 'MA',
    'Africa/Ceuta': 'ES',
    'Africa/Conakry': 'GN',
    'Africa/Dakar': 'SN',
    'Africa/Dar_es_Salaam': 'TZ',
    'Africa/Djibouti': 'DJ',
    'Africa/Douala': 'CM',
    'Africa/El_Aaiun': 'EH',
    'Africa/Freetown': 'SL',
    'Africa/Gaborone': 'BW',
    'Africa/Harare': 'ZW',
    'Africa/Johannesburg': 'ZA',
    'Africa/Juba': 'SS',
    'Africa/Kampala': 'UG',
    'Africa/Khartoum': 'SD',
    'Africa/Kigali': 'RW',
    'Africa/Kinshasa': 'CD',
    'Africa/Lagos': 'NG',
    'Africa/Libreville': 'GA',
    'Africa/Lome': 'TG',
    'Africa/Luanda': 'AO',
    'Africa/Lubumbashi': 'CD',
    'Africa/Lusaka': 'ZM',
    'Africa/Malabo': 'GQ',
    'Africa/Maputo': 'MZ',
    'Africa/Maseru': 'LS',
    'Africa/Mbabane': 'SZ',
    'Africa/Mogadishu': 'SO',
    'Africa/Monrovia': 'LR',
    'Africa/Nairobi': 'KE',
    'Africa/Ndjamena': 'TD',
    'Africa/Niamey': 'NE',
    'Africa/Nouakchott': 'MR',
    'Africa/Ouagadougou': 'BF',
    'Africa/Porto-Novo': 'BJ',
    'Africa/Sao_Tome': 'ST',
    'Africa/Timbuktu': 'ML',
    'Africa/Tripoli': 'LY',
    'Africa/Tunis': 'TN',
    'Africa/Windhoek': 'NA',
    'America/Adak': 'US',
    'America/Anchorage': 'US',
    'America/Anguilla': 'AI',
    'America/Antigua': 'AG',
    'America/Araguaina': 'BR',
    'America/Argentina/Buenos_Aires': 'AR',
    'America/Argentina/Catamarca': 'AR',
    'America/Argentina/Cordoba': 'AR',
    'America/Argentina/Jujuy': 'AR',
    'America/Argentina/La_Rioja': 'AR',
    'America/Argentina/Mendoza': 'AR',
    'America/Argentina/Rio_Gallegos': 'AR',
    'America/Argentina/Salta': 'AR',
    'America/Argentina/San_Juan': 'AR',
    'America/Argentina/San_Luis': 'AR',
    'America/Argentina/Tucuman': 'AR',
    'America/Argentina/Ushuaia': 'AR',
    'America/Aruba': 'AW',
    'America/Asuncion': 'PY',
    'America/Atikokan': 'CA',
    'America/Bahia': 'BR',
    'America/Bahia_Banderas': 'MX',
    'America/Barbados': 'BB',
    'America/Belem': 'BR',
    'America/Belize': 'BZ',
    'America/Blanc-Sablon': 'CA',
    'America/Boa_Vista': 'BR',
    'America/Bogota': 'CO',
    'America/Boise': 'US',
    'America/Cambridge_Bay': 'CA',
    'America/Campo_Grande': 'BR',
    'America/Cancun': 'MX',
    'America/Caracas': 'VE',
    'America/Cayenne': 'GF',
    'America/Cayman': 'KY',
    'America/Chicago': 'US',
    'America/Chihuahua': 'MX',
    'America/Ciudad_Juarez': 'MX',
    'America/Coral_Harbour': 'CA',
    'America/Costa_Rica': 'CR',
    'America/Creston': 'CA',
    'America/Cuiaba': 'BR',
    'America/Curacao': 'CW',
    'America/Danmarkshavn': 'GL',
    'America/Dawson': 'CA',
    'America/Dawson_Creek': 'CA',
    'America/Denver': 'US',
    'America/Detroit': 'US',
    'America/Dominica': 'DM',
    'America/Edmonton': 'CA',
    'America/Eirunepe': 'BR',
    'America/El_Salvador': 'SV',
    'America/Fort_Nelson': 'CA',
    'America/Fortaleza': 'BR',
    'America/Glace_Bay': 'CA',
    'America/Goose_Bay': 'CA',
    'America/Grand_Turk': 'TC',
    'America/Grenada': 'GD',
    'America/Guadeloupe': 'GP',
    'America/Guatemala': 'GT',
    'America/Guayaquil': 'EC',
    'America/Guyana': 'GY',
    'America/Halifax': 'CA',
    'America/Havana': 'CU',
    'America/Hermosillo': 'MX',
    'America/Indiana/Indianapolis': 'US',
    'America/Indiana/Knox': 'US',
    'America/Indiana/Marengo': 'US',
    'America/Indiana/Petersburg': 'US',
    'America/Indiana/Tell_City': 'US',
    'America/Indiana/Vevay': 'US',
    'America/Indiana/Vincennes': 'US',
    'America/Indiana/Winamac': 'US',
    'America/Inuvik': 'CA',
    'America/Iqaluit': 'CA',
    'America/Jamaica': 'JM',
    'America/Juneau': 'US',
    'America/Kentucky/Louisville': 'US',
    'America/Kentucky/Monticello': 'US',
    'America/Kralendijk': 'BQ',
    'America/La_Paz': 'BO',
    'America/Lima': 'PE',
    'America/Los_Angeles': 'US',
    'America/Lower_Princes': 'SX',
    'America/Maceio': 'BR',
    'America/Managua': 'NI',
    'America/Manaus': 'BR',
    'America/Marigot': 'MF',
    'America/Martinique': 'MQ',
    'America/Matamoros': 'MX',
    'America/Mazatlan': 'MX',
    'America/Menominee': 'US',
    'America/Merida': 'MX',
    'America/Metlakatla': 'US',
    'America/Mexico_City': 'MX',
    'America/Miquelon': 'PM',
    'America/Moncton': 'CA',
    'America/Monterrey': 'MX',
    'America/Montevideo': 'UY',
    'America/Montreal': 'CA',
    'America/Montserrat': 'MS',
    'America/Nassau': 'BS',
    'America/New_York': 'US',
    'America/Nipigon': 'CA',
    'America/Nome': 'US',
    'America/Noronha': 'BR',
    'America/North_Dakota/Beulah': 'US',
    'America/North_Dakota/Center': 'US',
    'America/North_Dakota/New_Salem': 'US',
    'America/Nuuk': 'GL',
    'America/Ojinaga': 'MX',
    'America/Panama': 'PA',
    'America/Paramaribo': 'SR',
    'America/Phoenix': 'US',
    'America/Port-au-Prince': 'HT',
    'America/Port_of_Spain': 'TT',
    'America/Porto_Velho': 'BR',
    'America/Puerto_Rico': 'PR',
    'America/Punta_Arenas': 'CL',
    'America/Rankin_Inlet': 'CA',
    'America/Recife': 'BR',
    'America/Regina': 'CA',
    'America/Resolute': 'CA',
    'America/Rio_Branco': 'BR',
    'America/Santarem': 'BR',
    'America/Santiago': 'CL',
    'America/Santo_Domingo': 'DO',
    'America/Sao_Paulo': 'BR',
    'America/Scoresbysund': 'GL',
    'America/Sitka': 'US',
    'America/St_Barthelemy': 'BL',
    'America/St_Johns': 'CA',
    'America/St_Kitts': 'KN',
    'America/St_Lucia': 'LC',
    'America/St_Thomas': 'VI',
    'America/St_Vincent': 'VC',
    'America/Swift_Current': 'CA',
    'America/Tegucigalpa': 'HN',
    'America/Thule': 'GL',
    'America/Thunder_Bay': 'CA',
    'America/Tijuana': 'MX',
    'America/Toronto': 'CA',
    'America/Tortola': 'VG',
    'America/Vancouver': 'CA',
    'America/Virgin': 'VI',
    'America/Whitehorse': 'CA',
    'America/Winnipeg': 'CA',
    'America/Yakutat': 'US',
    'Antarctica/Casey': 'AQ',
    'Antarctica/Davis': 'AQ',
    'Antarctica/DumontDUrville': 'AQ',
    'Antarctica/Macquarie': 'AU',
    'Antarctica/Mawson': 'AQ',
    'Antarctica/McMurdo': 'AQ',
    'Antarctica/Palmer': 'AQ',
    'Antarctica/Rothera': 'AQ',
    'Antarctica/South_Pole': 'AQ',
    'Antarctica/Syowa': 'AQ',
    'Antarctica/Troll': 'AQ',
    'Antarctica/Vostok': 'AQ',
    'Arctic/Longyearbyen': 'SJ',
    'Asia/Aden': 'YE',
    'Asia/Almaty': 'KZ',
    'Asia/Amman': 'JO',
    'Asia/Anadyr': 'RU',
    'Asia/Aqtau': 'KZ',
    'Asia/Aqtobe': 'KZ',
    'Asia/Ashgabat': 'TM',
    'Asia/Atyrau': 'KZ',
    'Asia/Baghdad': 'IQ',
    'Asia/Bahrain': 'BH',
    'Asia/Baku': 'AZ',
    'Asia/Bangkok': 'TH',
    'Asia/Barnaul': 'RU',
    'Asia/Beirut': 'LB',
    'Asia/Bishkek': 'KG',
    'Asia/Brunei': 'BN',
    'Asia/Chita': 'RU',
    'Asia/Choibalsan': 'MN',
    'Asia/Colombo': 'LK',
    'Asia/Damascus': 'SY',
    'Asia/Dhaka': 'BD',
    'Asia/Dili': 'TL',
    'Asia/Dubai': 'AE',
    'Asia/Dushanbe': 'TJ',
    'Asia/Famagusta': 'CY',
    'Asia/Gaza': 'PS',
    'Asia/Hebron': 'PS',
    'Asia/Ho_Chi_Minh': 'VN',
    'Asia/Hong_Kong': 'HK',
    'Asia/Hovd': 'MN',
    'Asia/Irkutsk': 'RU',
    'Asia/Jakarta': 'ID',
    'Asia/Jayapura': 'ID',
    'Asia/Jerusalem': 'IL',
    'Asia/Kabul': 'AF',
    'Asia/Kamchatka': 'RU',
    'Asia/Karachi': 'PK',
    'Asia/Kashgar': 'CN',
    'Asia/Kathmandu': 'NP',
    'Asia/Khandyga': 'RU',
    'Asia/Kolkata': 'IN',
    'Asia/Krasnoyarsk': 'RU',
    'Asia/Kuala_Lumpur': 'MY',
    'Asia/Kuching': 'MY',
    'Asia/Kuwait': 'KW',
    'Asia/Macau': 'MO',
    'Asia/Magadan': 'RU',
    'Asia/Makassar': 'ID',
    'Asia/Manila': 'PH',
    'Asia/Muscat': 'OM',
    'Asia/Nicosia': 'CY',
    'Asia/Novokuznetsk': 'RU',
    'Asia/Novosibirsk': 'RU',
    'Asia/Omsk': 'RU',
    'Asia/Oral': 'KZ',
    'Asia/Phnom_Penh': 'KH',
    'Asia/Pontianak': 'ID',
    'Asia/Pyongyang': 'KP',
    'Asia/Qatar': 'QA',
    'Asia/Qostanay': 'KZ',
    'Asia/Qyzylorda': 'KZ',
    'Asia/Rangoon': 'MM',
    'Asia/Riyadh': 'SA',
    'Asia/Sakhalin': 'RU',
    'Asia/Samarkand': 'UZ',
    'Asia/Seoul': 'KR',
    'Asia/Shanghai': 'CN',
    'Asia/Singapore': 'SG',
    'Asia/Srednekolymsk': 'RU',
    'Asia/Taipei': 'TW',
    'Asia/Tashkent': 'UZ',
    'Asia/Tbilisi': 'GE',
    'Asia/Tehran': 'IR',
    'Asia/Thimphu': 'BT',
    'Asia/Tokyo': 'JP',
    'Asia/Tomsk': 'RU',
    'Asia/Ulaanbaatar': 'MN',
    'Asia/Urumqi': 'CN',
    'Asia/Ust-Nera': 'RU',
    'Asia/Vientiane': 'LA',
    'Asia/Vladivostok': 'RU',
    'Asia/Yakutsk': 'RU',
    'Asia/Yangon': 'MM',
    'Asia/Yekaterinburg': 'RU',
    'Asia/Yerevan': 'AM',
    'Atlantic/Azores': 'PT',
    'Atlantic/Bermuda': 'BM',
    'Atlantic/Canary': 'ES',
    'Atlantic/Cape_Verde': 'CV',
    'Atlantic/Faroe': 'FO',
    'Atlantic/Jan_Mayen': 'SJ',
    'Atlantic/Madeira': 'PT',
    'Atlantic/Reykjavik': 'IS',
    'Atlantic/South_Georgia': 'GS',
    'Atlantic/St_Helena': 'SH',
    'Atlantic/Stanley': 'FK',
    'Australia/Adelaide': 'AU',
    'Australia/Brisbane': 'AU',
    'Australia/Broken_Hill': 'AU',
    'Australia/Darwin': 'AU',
    'Australia/Eucla': 'AU',
    'Australia/Hobart': 'AU',
    'Australia/Lindeman': 'AU',
    'Australia/Lord_Howe': 'AU',
    'Australia/Melbourne': 'AU',
    'Australia/Perth': 'AU',
    'Australia/Sydney': 'AU',
    'Canada/Eastern': 'CA',
    'Europe/Amsterdam': 'NL',
    'Europe/Andorra': 'AD',
    'Europe/Astrakhan': 'RU',
    'Europe/Athens': 'GR',
    'Europe/Belfast': 'GB',
    'Europe/Belgrade': 'RS',
    'Europe/Berlin': 'DE',
    'Europe/Bratislava': 'SK',
    'Europe/Brussels': 'BE',
    'Europe/Bucharest': 'RO',
    'Europe/Budapest': 'HU',
    'Europe/Busingen': 'DE',
    'Europe/Chisinau': 'MD',
    'Europe/Copenhagen': 'DK',
    'Europe/Dublin': 'IE',
    'Europe/Gibraltar': 'GI',
    'Europe/Guernsey': 'GG',
    'Europe/Helsinki': 'FI',
    'Europe/Isle_of_Man': 'IM',
    'Europe/Istanbul': 'TR',
    'Europe/Jersey': 'JE',
    'Europe/Kaliningrad': 'RU',
    'Europe/Kirov': 'RU',
    'Europe/Kyiv': 'UA',
    'Europe/Lisbon': 'PT',
    'Europe/Ljubljana': 'SI',
    'Europe/London': 'GB',
    'Europe/Luxembourg': 'LU',
    'Europe/Madrid': 'ES',
    'Europe/Malta': 'MT',
    'Europe/Mariehamn': 'AX',
    'Europe/Minsk': 'BY',
    'Europe/Monaco': 'MC',
    'Europe/Moscow': 'RU',
    'Europe/Oslo': 'NO',
    'Europe/Paris': 'FR',
    'Europe/Podgorica': 'ME',
    'Europe/Prague': 'CZ',
    'Europe/Riga': 'LV',
    'Europe/Rome': 'IT',
    'Europe/Samara': 'RU',
    'Europe/San_Marino': 'SM',
    'Europe/Sarajevo': 'BA',
    'Europe/Saratov': 'RU',
    'Europe/Simferopol': 'RU',
    'Europe/Skopje': 'MK',
    'Europe/Sofia': 'BG',
    'Europe/Stockholm': 'SE',
    'Europe/Tallinn': 'EE',
    'Europe/Tirane': 'AL',
    'Europe/Ulyanovsk': 'RU',
    'Europe/Vaduz': 'LI',
    'Europe/Vatican': 'VA',
    'Europe/Vienna': 'AT',
    'Europe/Vilnius': 'LT',
    'Europe/Volgograd': 'RU',
    'Europe/Warsaw': 'PL',
    'Europe/Zagreb': 'HR',
    'Europe/Zurich': 'CH',
    GB: 'GB',
    'GB-Eire': 'GB',
    Iceland: 'IS',
    'Indian/Antananarivo': 'MG',
    'Indian/Chagos': 'IO',
    'Indian/Christmas': 'CX',
    'Indian/Cocos': 'CC',
    'Indian/Comoro': 'KM',
    'Indian/Kerguelen': 'TF',
    'Indian/Mahe': 'SC',
    'Indian/Maldives': 'MV',
    'Indian/Mauritius': 'MU',
    'Indian/Mayotte': 'YT',
    'Indian/Reunion': 'RE',
    NZ: 'NZ',
    'Pacific/Apia': 'WS',
    'Pacific/Auckland': 'NZ',
    'Pacific/Bougainville': 'PG',
    'Pacific/Chatham': 'NZ',
    'Pacific/Chuuk': 'FM',
    'Pacific/Easter': 'CL',
    'Pacific/Efate': 'VU',
    'Pacific/Fakaofo': 'TK',
    'Pacific/Fiji': 'FJ',
    'Pacific/Funafuti': 'TV',
    'Pacific/Galapagos': 'EC',
    'Pacific/Gambier': 'PF',
    'Pacific/Guadalcanal': 'SB',
    'Pacific/Guam': 'GU',
    'Pacific/Honolulu': 'US',
    'Pacific/Kanton': 'KI',
    'Pacific/Kiritimati': 'KI',
    'Pacific/Kosrae': 'FM',
    'Pacific/Kwajalein': 'MH',
    'Pacific/Majuro': 'MH',
    'Pacific/Marquesas': 'PF',
    'Pacific/Midway': 'UM',
    'Pacific/Nauru': 'NR',
    'Pacific/Niue': 'NU',
    'Pacific/Norfolk': 'NF',
    'Pacific/Noumea': 'NC',
    'Pacific/Pago_Pago': 'AS',
    'Pacific/Palau': 'PW',
    'Pacific/Pitcairn': 'PN',
    'Pacific/Pohnpei': 'FM',
    'Pacific/Ponape': 'FM',
    'Pacific/Port_Moresby': 'PG',
    'Pacific/Rarotonga': 'CK',
    'Pacific/Saipan': 'MP',
    'Pacific/Samoa': 'AS',
    'Pacific/Tahiti': 'PF',
    'Pacific/Tarawa': 'KI',
    'Pacific/Tongatapu': 'TO',
    'Pacific/Truk': 'FM',
    'Pacific/Wake': 'UM',
    'Pacific/Wallis': 'WF',
    'Pacific/Yap': 'FM',
    Singapore: 'SG',
    'US/Arizona': 'US',
    'US/Samoa': 'AS',
}

export const CountrySelectItems: SelectItem<string>[] = [
    { label: 'Afghanistan', id: 'AF' },
    { label: 'Aland Islands', id: 'AX' },
    { label: 'Albania', id: 'AL' },
    { label: 'Algeria', id: 'DZ' },
    { label: 'American Samoa', id: 'AS' },
    { label: 'AndorrA', id: 'AD' },
    { label: 'Angola', id: 'AO' },
    { label: 'Anguilla', id: 'AI' },
    { label: 'Antarctica', id: 'AQ' },
    { label: 'Antigua and Barbuda', id: 'AG' },
    { label: 'Argentina', id: 'AR' },
    { label: 'Armenia', id: 'AM' },
    { label: 'Aruba', id: 'AW' },
    { label: 'Australia', id: 'AU' },
    { label: 'Austria', id: 'AT' },
    { label: 'Azerbaijan', id: 'AZ' },
    { label: 'Bahamas', id: 'BS' },
    { label: 'Bahrain', id: 'BH' },
    { label: 'Bangladesh', id: 'BD' },
    { label: 'Barbados', id: 'BB' },
    { label: 'Belarus', id: 'BY' },
    { label: 'Belgium', id: 'BE' },
    { label: 'Belize', id: 'BZ' },
    { label: 'Benin', id: 'BJ' },
    { label: 'Bermuda', id: 'BM' },
    { label: 'Bhutan', id: 'BT' },
    { label: 'Bolivia', id: 'BO' },
    { label: 'Bosnia and Herzegovina', id: 'BA' },
    { label: 'Botswana', id: 'BW' },
    { label: 'Bouvet Island', id: 'BV' },
    { label: 'Brazil', id: 'BR' },
    { label: 'British Indian Ocean Territory', id: 'IO' },
    { label: 'Brunei Darussalam', id: 'BN' },
    { label: 'Bulgaria', id: 'BG' },
    { label: 'Burkina Faso', id: 'BF' },
    { label: 'Burundi', id: 'BI' },
    { label: 'Cambodia', id: 'KH' },
    { label: 'Cameroon', id: 'CM' },
    { label: 'Canada', id: 'CA' },
    { label: 'Cape Verde', id: 'CV' },
    { label: 'Cayman Islands', id: 'KY' },
    { label: 'Central African Republic', id: 'CF' },
    { label: 'Chad', id: 'TD' },
    { label: 'Chile', id: 'CL' },
    { label: 'China', id: 'CN' },
    { label: 'Christmas Island', id: 'CX' },
    { label: 'Cocos (Keeling) Islands', id: 'CC' },
    { label: 'Colombia', id: 'CO' },
    { label: 'Comoros', id: 'KM' },
    { label: 'Congo', id: 'CG' },
    { label: 'Congo, The Democratic Republic of the', id: 'CD' },
    { label: 'Cook Islands', id: 'CK' },
    { label: 'Costa Rica', id: 'CR' },
    { label: "Cote D'Ivoire", id: 'CI' },
    { label: 'Croatia', id: 'HR' },
    { label: 'Cuba', id: 'CU' },
    { label: 'Cyprus', id: 'CY' },
    { label: 'Czech Republic', id: 'CZ' },
    { label: 'Denmark', id: 'DK' },
    { label: 'Djibouti', id: 'DJ' },
    { label: 'Dominica', id: 'DM' },
    { label: 'Dominican Republic', id: 'DO' },
    { label: 'Ecuador', id: 'EC' },
    { label: 'Egypt', id: 'EG' },
    { label: 'El Salvador', id: 'SV' },
    { label: 'Equatorial Guinea', id: 'GQ' },
    { label: 'Eritrea', id: 'ER' },
    { label: 'Estonia', id: 'EE' },
    { label: 'Ethiopia', id: 'ET' },
    { label: 'Falkland Islands (Malvinas)', id: 'FK' },
    { label: 'Faroe Islands', id: 'FO' },
    { label: 'Fiji', id: 'FJ' },
    { label: 'Finland', id: 'FI' },
    { label: 'France', id: 'FR' },
    { label: 'French Guiana', id: 'GF' },
    { label: 'French Polynesia', id: 'PF' },
    { label: 'French Southern Territories', id: 'TF' },
    { label: 'Gabon', id: 'GA' },
    { label: 'Gambia', id: 'GM' },
    { label: 'Georgia', id: 'GE' },
    { label: 'Germany', id: 'DE' },
    { label: 'Ghana', id: 'GH' },
    { label: 'Gibraltar', id: 'GI' },
    { label: 'Greece', id: 'GR' },
    { label: 'Greenland', id: 'GL' },
    { label: 'Grenada', id: 'GD' },
    { label: 'Guadeloupe', id: 'GP' },
    { label: 'Guam', id: 'GU' },
    { label: 'Guatemala', id: 'GT' },
    { label: 'Guernsey', id: 'GG' },
    { label: 'Guinea', id: 'GN' },
    { label: 'Guinea-Bissau', id: 'GW' },
    { label: 'Guyana', id: 'GY' },
    { label: 'Haiti', id: 'HT' },
    { label: 'Heard Island and Mcdonald Islands', id: 'HM' },
    { label: 'Holy See (Vatican City State)', id: 'VA' },
    { label: 'Honduras', id: 'HN' },
    { label: 'Hong Kong', id: 'HK' },
    { label: 'Hungary', id: 'HU' },
    { label: 'Iceland', id: 'IS' },
    { label: 'India', id: 'IN' },
    { label: 'Indonesia', id: 'ID' },
    { label: 'Iran, Islamic Republic Of', id: 'IR' },
    { label: 'Iraq', id: 'IQ' },
    { label: 'Ireland', id: 'IE' },
    { label: 'Isle of Man', id: 'IM' },
    { label: 'Israel', id: 'IL' },
    { label: 'Italy', id: 'IT' },
    { label: 'Jamaica', id: 'JM' },
    { label: 'Japan', id: 'JP' },
    { label: 'Jersey', id: 'JE' },
    { label: 'Jordan', id: 'JO' },
    { label: 'Kazakhstan', id: 'KZ' },
    { label: 'Kenya', id: 'KE' },
    { label: 'Kiribati', id: 'KI' },
    { label: "Korea, Democratic People'S Republic of", id: 'KP' },
    { label: 'Korea, Republic of', id: 'KR' },
    { label: 'Kuwait', id: 'KW' },
    { label: 'Kyrgyzstan', id: 'KG' },
    { label: "Lao People'S Democratic Republic", id: 'LA' },
    { label: 'Latvia', id: 'LV' },
    { label: 'Lebanon', id: 'LB' },
    { label: 'Lesotho', id: 'LS' },
    { label: 'Liberia', id: 'LR' },
    { label: 'Libyan Arab Jamahiriya', id: 'LY' },
    { label: 'Liechtenstein', id: 'LI' },
    { label: 'Lithuania', id: 'LT' },
    { label: 'Luxembourg', id: 'LU' },
    { label: 'Macao', id: 'MO' },
    { label: 'Macedonia, The Former Yugoslav Republic of', id: 'MK' },
    { label: 'Madagascar', id: 'MG' },
    { label: 'Malawi', id: 'MW' },
    { label: 'Malaysia', id: 'MY' },
    { label: 'Maldives', id: 'MV' },
    { label: 'Mali', id: 'ML' },
    { label: 'Malta', id: 'MT' },
    { label: 'Marshall Islands', id: 'MH' },
    { label: 'Martinique', id: 'MQ' },
    { label: 'Mauritania', id: 'MR' },
    { label: 'Mauritius', id: 'MU' },
    { label: 'Mayotte', id: 'YT' },
    { label: 'Mexico', id: 'MX' },
    { label: 'Micronesia, Federated States of', id: 'FM' },
    { label: 'Moldova, Republic of', id: 'MD' },
    { label: 'Monaco', id: 'MC' },
    { label: 'Mongolia', id: 'MN' },
    { label: 'Montenegro', id: 'ME' },
    { label: 'Montserrat', id: 'MS' },
    { label: 'Morocco', id: 'MA' },
    { label: 'Mozambique', id: 'MZ' },
    { label: 'Myanmar', id: 'MM' },
    { label: 'Namibia', id: 'NA' },
    { label: 'Nauru', id: 'NR' },
    { label: 'Nepal', id: 'NP' },
    { label: 'Netherlands', id: 'NL' },
    { label: 'Netherlands Antilles', id: 'AN' },
    { label: 'New Caledonia', id: 'NC' },
    { label: 'New Zealand', id: 'NZ' },
    { label: 'Nicaragua', id: 'NI' },
    { label: 'Niger', id: 'NE' },
    { label: 'Nigeria', id: 'NG' },
    { label: 'Niue', id: 'NU' },
    { label: 'Norfolk Island', id: 'NF' },
    { label: 'Northern Mariana Islands', id: 'MP' },
    { label: 'Norway', id: 'NO' },
    { label: 'Oman', id: 'OM' },
    { label: 'Pakistan', id: 'PK' },
    { label: 'Palau', id: 'PW' },
    { label: 'Palestinian Territory, Occupied', id: 'PS' },
    { label: 'Panama', id: 'PA' },
    { label: 'Papua New Guinea', id: 'PG' },
    { label: 'Paraguay', id: 'PY' },
    { label: 'Peru', id: 'PE' },
    { label: 'Philippines', id: 'PH' },
    { label: 'Pitcairn', id: 'PN' },
    { label: 'Poland', id: 'PL' },
    { label: 'Portugal', id: 'PT' },
    { label: 'Puerto Rico', id: 'PR' },
    { label: 'Qatar', id: 'QA' },
    { label: 'Reunion', id: 'RE' },
    { label: 'Romania', id: 'RO' },
    { label: 'Russian Federation', id: 'RU' },
    { label: 'RWANDA', id: 'RW' },
    { label: 'Saint Helena', id: 'SH' },
    { label: 'Saint Kitts and Nevis', id: 'KN' },
    { label: 'Saint Lucia', id: 'LC' },
    { label: 'Saint Pierre and Miquelon', id: 'PM' },
    { label: 'Saint Vincent and the Grenadines', id: 'VC' },
    { label: 'Samoa', id: 'WS' },
    { label: 'San Marino', id: 'SM' },
    { label: 'Sao Tome and Principe', id: 'ST' },
    { label: 'Saudi Arabia', id: 'SA' },
    { label: 'Senegal', id: 'SN' },
    { label: 'Serbia', id: 'RS' },
    { label: 'Seychelles', id: 'SC' },
    { label: 'Sierra Leone', id: 'SL' },
    { label: 'Singapore', id: 'SG' },
    { label: 'Slovakia', id: 'SK' },
    { label: 'Slovenia', id: 'SI' },
    { label: 'Solomon Islands', id: 'SB' },
    { label: 'Somalia', id: 'SO' },
    { label: 'South Africa', id: 'ZA' },
    { label: 'South Georgia and the South Sandwich Islands', id: 'GS' },
    { label: 'Spain', id: 'ES' },
    { label: 'Sri Lanka', id: 'LK' },
    { label: 'Sudan', id: 'SD' },
    { label: 'Suriname', id: 'SR' },
    { label: 'Svalbard and Jan Mayen', id: 'SJ' },
    { label: 'Swaziland', id: 'SZ' },
    { label: 'Sweden', id: 'SE' },
    { label: 'Switzerland', id: 'CH' },
    { label: 'Syrian Arab Republic', id: 'SY' },
    { label: 'Taiwan, Province of China', id: 'TW' },
    { label: 'Tajikistan', id: 'TJ' },
    { label: 'Tanzania, United Republic of', id: 'TZ' },
    { label: 'Thailand', id: 'TH' },
    { label: 'Timor-Leste', id: 'TL' },
    { label: 'Togo', id: 'TG' },
    { label: 'Tokelau', id: 'TK' },
    { label: 'Tonga', id: 'TO' },
    { label: 'Trinidad and Tobago', id: 'TT' },
    { label: 'Tunisia', id: 'TN' },
    { label: 'Turkey', id: 'TR' },
    { label: 'Turkmenistan', id: 'TM' },
    { label: 'Turks and Caicos Islands', id: 'TC' },
    { label: 'Tuvalu', id: 'TV' },
    { label: 'Uganda', id: 'UG' },
    { label: 'Ukraine', id: 'UA' },
    { label: 'United Arab Emirates', id: 'AE' },
    { label: 'United Kingdom', id: 'GB' },
    { label: 'United States', id: 'US' },
    { label: 'United States Minor Outlying Islands', id: 'UM' },
    { label: 'Uruguay', id: 'UY' },
    { label: 'Uzbekistan', id: 'UZ' },
    { label: 'Vanuatu', id: 'VU' },
    { label: 'Venezuela', id: 'VE' },
    { label: 'Vietnam', id: 'VN' },
    { label: 'Virgin Islands, British', id: 'VG' },
    { label: 'Virgin Islands, U.S.', id: 'VI' },
    { label: 'Wallis and Futuna', id: 'WF' },
    { label: 'Western Sahara', id: 'EH' },
    { label: 'Yemen', id: 'YE' },
    { label: 'Zambia', id: 'ZM' },
    { label: 'Zimbabwe', id: 'ZW' },
]
